<template>
  <div v-if="!isLoading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'TenantIndex' }">
            Tenant Management
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2">
            {{ company.companyName }}
          </span>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2">
            Detail
          </span>
        </li>
      </ul>
    </nav>

    <div class="columns is-multiline">
      <div class="column">
        <div class="box" style="border-color:black">
          <div class="columns">
            <div class="column is-5 ">
              IDPJK
            </div>
            <div class="column is-5 is-offset-1 ">
              {{ company.idpjk }}
            </div>
          </div>
          <div class="columns">
            <div class="column is-5 ">
              Nama Tenant
            </div>
            <div class="column is-5 is-offset-1 ">
              {{ company.companyName }}
            </div>
          </div>
          <div class="columns">
            <div class="column is-5 ">
              No.Telepon
            </div>
            <div class="column is-5 is-offset-1 ">
              {{ company.phoneNumber }}
            </div>
          </div>
          <div class="columns">
            <div class="column is-5 ">
              Alamat
            </div>
            <div class="column is-5 is-offset-1 ">
              {{ company.address }}
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box" style="border-color:black">
          <div class="columns">
            <div class="column is-5 ">
              Bergabung
            </div>
            <div class="column is-5 is-offset-1 ">
              {{ company.joined }}
            </div>
          </div>
          <div class="columns">
            <div class="column is-5 ">
              Berlangganan
            </div>
            <div class="column is-5 is-offset-1 ">
              {{ company.activeDate }}
            </div>
          </div>
          <div class="columns">
            <div class="column is-5 ">
              Jenis Paket
            </div>
            <div class="column is-5 is-offset-1 ">
              {{ company.billingName }}
            </div>
          </div>
          <div class="columns">
            <div class="column is-5 ">
              Masa Aktif
            </div>
            <div class="column is-5 is-offset-1 ">
              <span
                v-if="
                  company.remain.remainDay >= 0 && company.billingName !== ''
                "
              >
                {{ company.remain.remainDay }} Hari
              </span>
              <span class="has-text-red" v-else>Expire</span>
            </div>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>

    <b-select
      class="mb-3"
      v-model="perPage"
      placeholder="Per-Page"
      :value="perPage"
      @input="getAddOnCompanyManagementDetail($route.params.companyid)"
    >
      <option
        v-for="option in perPages"
        :value="option.value"
        :key="option.value"
      >
        {{ option.text }}
      </option>
    </b-select>

    <b-table
      :data="addons"
      :loading="loading"
      paginated
      backend-pagination
      :total="rows"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column label="No" v-slot="props">
        {{ (page - 1) * perPage + props.index + 1 }}
      </b-table-column>

      <b-table-column
        field="add ons name"
        sortable
        label="Nama Add Ons"
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column field="description" label="Deskripsi" v-slot="props">
        {{ props.row.description }}
      </b-table-column>

      <b-table-column field="type" sortable label="Tipe" v-slot="props">
        <span v-if="props.row.price === 0">Gratis</span>
        <span v-else>Berbayar ( {{ props.row.price | toCurrency }})</span>
      </b-table-column>

      <b-table-column
        field="active Date"
        sortable
        label="Tanggal Berlangganan"
        v-slot="props"
      >
        {{ props.row.activeDate }}
      </b-table-column>

      <b-table-column
        field="remain"
        sortable
        label="Masa aktif "
        v-slot="props"
      >
        <span v-if="props.row.price != 0 && props.row.remain.remainDay >= 0">
          {{ props.row.remain.remainDay }} Hari
        </span>
        <span v-else-if="props.row.price == 0">Gratis</span>
        <span class="has-text-red" v-else>Expire</span>
      </b-table-column>
      <template slot="bottom-left">
        <div class="has-text-left">
          Page {{ page }} From
          {{
            Math.ceil(rows / perPage) == '0' ? '1' : Math.ceil(rows / perPage)
          }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: '',
      total: 0,
      loading: false,
      sortField: 'id',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      page: 1,
      perPage: 5,
      addons: [],
      isLoading: true,

      perPages: [
        { text: '5', value: '5' },
        { text: '10', value: '10' },
        { text: '20', value: '20' },
        { text: '50', value: '50' },
        { text: '100', value: '100' },
      ],
    }
  },
  created() {
    this.getCompanyManagementDetail(this.$route.params.companyid)
    this.getAddOnCompanyManagementDetail(this.$route.params.companyid)
  },
  computed: {
    // mix the getters into computed with object spread operator
    rows() {
      return this.total
    },
  },
  methods: {
    getCompanyManagementDetail(id) {
      this.$store
        .dispatch('getCompanyManagementDetail', { id })
        .then(response => {
          if (response != null) {
            this.company = response
          } else {
            this.company = ''
          }
          this.isLoading = false
        })
    },
    getAddOnCompanyManagementDetail: function(id) {
      const params = [
        `sort_by=${this.sortField}`,
        `order=${this.sortOrder}`,
        `page=${this.page}`,
        `per_page=${this.perPage}`,
      ].join('&')
      this.loading = true

      this.$store
        .dispatch('getAddOnCompanyManagementDetail', { id, params })
        .then(response => {
          if (response.data != null) {
            this.addons = response.data
          } else {
            this.addons = []
          }
          this.loading = false
          this.total = response.total
        })
    },
    onPageChange(page) {
      this.page = page
      this.getAddOnCompanyManagementDetail(this.$route.params.companyid)
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.getAddOnCompanyManagementDetail(this.$route.params.companyid)
    },
  },
}
</script>
